<template>
  <div class="report_detail_summary">
    <div class="report_detail_summary_header">{{ $t("term.facility") }}</div>
    <div
      class="report_detail_summary_site"
      v-if="selectedFacility"
      :style="{ width: getWidth(selectedFacility.name) }"
    >
      {{ selectedFacility.name }}
    </div>
    <div class="report_detail_summary_body">
      <div class="report_detail_summary_content">
        <div class="report_detail_summary_content_title">
          {{ $t("term.totalPhotos") }}
        </div>
        <div class="report_detail_summary_content_value">
          {{ photoList.length }}
        </div>
      </div>
      <div class="report_detail_summary_content">
        <div class="report_detail_summary_content_title">
          {{ $t("term.totalDefects") }}
        </div>
        <div class="report_detail_summary_content_value">{{ totalCount }}</div>
      </div>
      <div class="report_detail_summary_content" v-if="selectedPhoto?.camera">
        <div class="report_detail_summary_content_title">
          {{ $t("term.cameraManufacturer") }}
        </div>
        <div class="report_detail_summary_content_value">
          {{ selectedPhoto.camera.company }}
        </div>
      </div>
      <div class="report_detail_summary_content" v-if="selectedPhoto?.camera">
        <div class="report_detail_summary_content_title">
          {{ $t("term.cameraModel") }}
        </div>
        <div class="report_detail_summary_content_value">
          {{ selectedPhoto.camera.modelName }}
        </div>
      </div>
      <div class="report_detail_summary_content" v-if="selectedPhoto?.camera">
        <div class="report_detail_summary_content_title">
          {{ $t("term.photoSize") }}
        </div>
        <div class="report_detail_summary_content_value">
          {{ selectedPhoto.camera.photoWidth }} * {{ selectedPhoto.camera.photoHeight }}
        </div>
      </div>
      <div class="report_detail_summary_content" v-if="selectedPhoto?.camera">
        <div class="report_detail_summary_content_title">
          {{ $t("term.createdDateTime") }}
        </div>
        <div class="report_detail_summary_content_value">
          {{ selectedPhoto.camera.createdDatetime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    selectedAlbumList: Array,
    selectedPhoto: Object,
    photoList: Array,
  },
  computed: {
    ...mapState(["selectedFacility"]),
    getWidth() {
      return (siteName) => {
        if (siteName) {
          return siteName.length * 18 + 10 + "px";
        } else {
          return "70px";
        }
      };
    },
    totalCount() {
      let totalCount = this.selectedAlbumList.reduce(
        (sum, album) => sum + (album.crackCount || 0) + (album.otherCount || 0), 0
      );
      return totalCount;
    },
  },
  methods: {
    homeRouter() {
      this.$router.push({ name: "home" });
    },
  }
};
</script>

<style lang="scss">
.report_detail_summary {
  position: absolute;
  top: 75px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: rgba(23, 23, 23, 0.6);
  width: calc(100% / 4 * 3);
  height: 260px;
  max-width: 310px;
  margin: 15px 0px 0px 15px;
  padding: 20px;
  border-radius: 10px;
}
.report_detail_summary_header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: $main_white;
}
.report_detail_summary_site {
  @include flexbox;
  width: 100%;
  height: 30px;
  color: $main_white;
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 20px;
}
.report_detail_summary_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 150px;
}
.report_detail_summary_content {
  display: flex;
  width: 100%;
  height: 25px;
}
.report_detail_summary_content_title {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  color: $main_white;
  font-size: 1.1rem;
}
.report_detail_summary_content_value {
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: calc(100% - 100px);
  height: 20px;
  background: rgba(32, 32, 32, 0.85);
  border-radius: 5px;
  color: $main_white;
  font-size: 1.1rem;
}
</style>
