import { createStore } from "vuex";
import router from "./router.js";
import { isTouchDevice } from "@/shared/util/isTouchDevice.js";
import API from "./module/Communication/RESTAPI.js";
import io from "socket.io-client";

const socket = io(`wss://www.sirius-ex.co.kr/logout`);
const store = createStore({
  state: {
    // start: jimin
    user: {
      id: null,
      name: null,
      contact: null,
      createdDatetime: null,
      fullName: null,
      loggedIn: null,
      profileImageUrl: null,
      role: null,
      taskStatus: null,
      facility: null,
      affiliation: null,
    },
    alertState: {
      isAlertOpened: false,
      alertMessage: "",
    },
    isLoading: false,
    isTouchDevice: false,
    facilityEditInfo: null,
    facilityTypeCounts: null,
    selectedUserInfo: null,
    deviceType: "desktop",
    selectedFacilityMap: null,
    // end: jimin
    isMobile: false,
    isDownloading: false,
    apiIp: "211.224.129.230",
    apiPort: "10010",
    selectedFacility: null,
    api: new API("211.224.129.230", "10010"),
  },
  mutations: {
    setUser(state, { newValue, patch }) {
      if (newValue.id) {
        state.api.putUser(
          { isLoggedIn: true, taskStatus: "waiting" },
          newValue.id
        );
        // state.api.putUser({"taskStatus" : "waiting"}, newValue.id);
      } else {
        if (patch) {
          state.api.putUser(
            { isLoggedIn: false, taskStatus: "waiting" },
            state.user.id
          );
        }
        // state.api.putUser({"taskStatus" : "waiting"}, state.user.id);
      }
      state.user.affiliation = newValue.affiliation;
      state.user.contact = newValue.contact;
      state.user.createdDatetime = newValue.createdDatetime;
      state.user.facility = newValue.facility;
      state.user.fullName = newValue.fullName;
      state.user.id = newValue.id;
      state.user.loggedIn = newValue.loggedIn;
      state.user.profileImageUrl = newValue.profileImageUrl;
      state.user.role = newValue.role;
      state.user.taskStatus = newValue.taskStatus;
      state.user.userLoginId = newValue.userLoginId;
    },
    openAlert(state, newValue) {
      state.alertState.isAlertOpened = true;
      state.alertState.alertMessage = newValue;
    },
    closeAlert(state) {
      state.alertState.isAlertOpened = false;
      state.alertState.alertMessage = "";
    },
    setIsLoading(state, newValue) {
      state.isLoading = newValue;
    },
    setTouchDevice(state, newValue) {
      state.isTouchDevice = newValue;
    },
    setFacilityTypeCounts(state, newValue) {
      state.facilityTypeCounts = newValue;
    },
    setSelectedFacilityMap(state, newValue) {
      state.selectedFacilityMap = newValue;
    },
    setSelectedUserInfo(state, newValue) {
      state.selectedUserInfo = newValue;
    },
    setDeviceType(state, deviceType) {
      state.deviceType = deviceType;
    },
    // end: jimin
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setSelectedFacility(state, selectedFacility) {
      state.selectedFacility = selectedFacility;
    },
    setDownloading(state, boolean) {
      state.isDownloading = boolean;
    },
  },
  getters: {
    // start: jimin
    isTouchDevice: (state) => state.isTouchDevice,
    deviceType: (state) => state.deviceType,
    // end: jimin
  },
  actions: {
    login({ commit }, userInfo, patch) {
      sessionStorage.setItem("user", JSON.stringify(userInfo));
      commit("setUser", { newValue: userInfo, patch: patch });
    },
    logout({ commit }, patch) {
      commit("setUser", {
        newValue: {
          affiliation: null,
          contact: null,
          createdDatetime: null,
          facility: null,
          fullName: null,
          id: null,
          loggedIn: null,
          profileImageUrl: null,
          role: null,
          taskStatus: null,
          userLoginId: null,
        },
        patch: patch,
      });
      sessionStorage.removeItem("user");
      router.push("/login");
    },
    checkDeviceType({ commit }) {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /mobile|android|touch|webos|iphone|ipad|ipod/i.test(
        userAgent
      );
      commit("setIsMobile", isMobile);
    },
    setSelectedFacility({ commit }, selectedFacility) {
      commit("setSelectedFacility", selectedFacility);
    },
    // jimin
    detectTouchDevice({ commit }) {
      const touchDevice = isTouchDevice();
      commit("setTouchDevice", touchDevice);
    },
    sendMessageBySocket(_, userId) {
      socket.emit("message", userId, socket.id);
    },
    updateDeviceType({ commit }) {
      const width = window.innerWidth;

      if (width <= 767) {
        commit("setDeviceType", "mobile");
      } else if (width <= 1024) {
        commit("setDeviceType", "tablet");
      } else {
        commit("setDeviceType", "desktop");
      }
    },
  },
});

socket.on("message", (userId, socketId) => {
  const store = require("./store.js").default;
  if (
    store.state.user.id == userId &&
    store.state.user.id &&
    socket.id !== socketId
  ) {
    store.dispatch("logout", false);
    store.commit(
      "openAlert",
      `다른 환경에서 로그인되어 현재 사용 중인 환경에서 로그아웃 되었습니다.`
    );
  }
});

export default store;

window.addEventListener("beforeunload", () => {
  if (store.state.user.id && store.state.api && !store.state.isDownloading) {
    store.state.api.putUser(
      { isLoggedIn: false, taskStatus: "waiting" },
      store.state.user.id
    );
  }
});
