export default class DroneData {
  constructor() {
    this.fcu = false;
    this.armPosition = { x: 0, y: 0, yaw: 0 };
    this.state = {
      rtl: false,
      rth: false,
      localization: true,
      arming: false,
      takeOff: false,
      mission: false,
      waypointNumber: 0,
      collision: false,
    };
    this.battery = null;
    this.gpsMode = false;
    this.flightMode = null;
    this.speed = null;
    this.slamState = false;
    this.storage = 0;
    this.pose = { x: 0, y: 0, z: 0, roll: 0, pitch: 0, yaw: 0 };
    this.timeCheck = false;
    this.armingTime = 0;
    this.flightTime = { h: 0, m: 0, s: 0 };
    this.sensorState = { lidar: false, imu: false, encoder: false };
  }
}
