export default class JoyStickData {
  constructor() {
    this.joyStickReturnValue = {};
    this.joyStickSensitivity = 0.75;
    this.isJoyStickTouchActive = false;
    this.joyStickInterval = null;
    this.joyStickDir = "all";
    this.shootingMode = "";
    this.gimbalPitch = 0;
    this.gimbalYaw = 0;
    this.dayNightTime = "";
    this.intervalShootingOn = false;
  }
}
