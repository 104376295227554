<template>
  <div
    id="joystick_container"
    ref="joyStickContainer"
    :class="joyStickData.joyStickDir"
    :style="{
      width: `${joyStickOption.size}px`,
      height: `${joyStickOption.size}px`,
    }"
  >
    <div class="joystick_box" ref="joyStickBox"></div>
    <div class="small_button_box">
      <button
        v-if="joyStickData.joyStickDir"
        type="button"
        @click="changeJoyStickDir"
        class="joystick_small_button direction_button"
        :class="joyStickData.joyStickDir"
      >
        <span v-if="joyStickData.joyStickDir === 'all'">all</span>
        <span v-if="joyStickData.joyStickDir === 'yaw'">horizon</span>
        <span v-if="joyStickData.joyStickDir === 'pitch'">vertical</span>
      </button>
      <button
        v-if="hasResetButton"
        type="button"
        @click="resetJoyStick"
        class="joystick_small_button reset_button"
      ></button>
    </div>
  </div>
</template>
<script>
import nipplejs from "nipplejs";

export default {
  name: "JoyStick",
  emits: [
    "handleJoyStickReturnValue",
    "handleJoyStickEndMethod",
    "handleJoyStickStartMethod",
    "handleResetJoyStickData",
    "handleChangeJoyStickDir",
  ],
  props: {
    joyStickData: {
      type: Object,
      require: true,
    },
    joyStickCustomOption: {
      type: Object,
      require: true,
    },
    hasResetButton: {
      type: Boolean,
      require: false,
      default: false,
    },
    hasMakeVideoBigger: {
      type: Boolean,
      require: false,
    },
  },
  data() {
    return {
      isLoading: true,
      joyStickOption: this.joyStickCustomOption,
      joystick: null,
      touchTimer: null, // 타이머
      // currentPosition: { x: 0, y: 0 }, // 현재 위치
      storedAngle: null, // 저장된 각도
      storedForce: null, // 저장된 힘
    };
  },
  methods: {
    handleStart(evt, data) {
      this.$emit("handleJoyStickStartMethod");

      // 초기 각도와 힘 저장
      this.storedAngle = data.angle ? data.angle.degree : null;
      this.storedForce = data.force;
    },
    handleMove(evt, data) {
      this.$emit("handleJoyStickReturnValue", data);
    },
    handleEnd() {
      this.$emit("handleJoyStickEndMethod");
      // clearInterval(this.touchTimer);
      // this.touchTimer = null;
    },
    createJoyStick(option) {
      this.joystick = nipplejs.create(option);

      this.joystick.on("start", this.handleStart);
      this.joystick.on("move", this.handleMove);
      this.joystick.on("end", this.handleEnd);
    },
    resetJoyStick() {
      this.$emit("handleResetJoyStickData");
    },
    changeJoyStickDir() {
      this.$emit("handleChangeJoyStickDir");
    },
  },
  mounted() {
    this.joyStickOption.zone = this.$refs.joyStickBox;

    // 이벤트 등록
    this.createJoyStick(this.joyStickOption);
  },
  beforeUnmount() {
    // 이벤트 및 타이머 정리
    if (this.joystick) {
      this.joystick.destroy();
    }
    clearInterval(this.touchTimer);
  },
};
</script>
<style lang="scss" scoped>
#joystick_container {
  position: relative;
  height: 90%;
  @include flexbox(center, center, row);
}

.joystick_box {
  position: relative;
  height: 90%;
  width: 90%;
}

.small_button_box {
  position: absolute;
  left: 6px;
  top: -39px;
  width: 40px;
  height: 40px;

  .joystick_small_button {
    min-width: 36px;
    min-height: 36px;
    border-radius: 50px;
    background: $main_black;
    border: 2px solid $gray_6e6e82;
    color: $main_white;

    &.reset_button {
      background: $main_black url(@/../public/images/arrow_rotate_white.svg)
        no-repeat center;
      background-size: 55%;
    }

    &.direction_button {
      position: absolute;
      left: -56px;
      bottom: -46px;
      min-width: 54px;
      min-height: 54px;

      span {
        font-size: 1.2rem;
        padding: 5px;
        min-width: 59px;
      }

      &.all {
        border: 2px solid $main_color;
      }

      &.yaw {
        border: 2px solid $pink_ff2f9f;
      }

      &.pitch {
        border: 2px solid $yellow_ffc83c;
      }
    }
  }
}
</style>
