<template>
  <div class="report_list_container">
    <div class="report_list_wrap">
      <div class="report_list_title">{{ $t("term.albumList") }}</div>
      <div
        class="report_list_add_button button"
        :class="{ on: uploadState }"
        @click="openUploadModal"
      ></div>
    </div>
    <div class="report_map_item_list">
      <div
        v-for="(album, albumIndex) in albumList"
        :key="albumIndex"
        class="report_album_item"
      >
        <div
          class="report_item_check_box"
          @click="selectAlbum(album)"
          :class="{ on: checkSelectedAlbumList(album.id) }"
        ></div>
        <div class="report_item_name">{{ album.name }}</div>
        <div
          class="report_expand_menu_button button"
          @click="expandAlbumMenu(album)"
          :class="{ on: expandAlbum === album }"
        ></div>
      </div>
    </div>
  </div>
  <div class="report_ablum_expand_menu_modal" v-if="expandAlbum">
    <div class="report_ablum_ananlysis_state">
      {{ $t("term.analyzeState") }} : {{ getStatus }}
    </div>
    <!-- <div
      v-if="!selectedAlbumInfo.model"
      class="report_ablum_button button"
      @click="openUploadPatternModal(true)"
    >
      {{ $t("term.crackPatternMapUpload") }}
    </div> -->
    <!-- <div
      class="report_ablum_button button"
      v-if="selectedAlbumInfo.model"
      @click.stop="routerPattern(expandAlbum.id, expandAlbum.model.id)"
    >

      {{ $t("term.crackPatternMapRead") }}
    </div>
    <div
      v-if="selectedAlbumInfo.model"
      class="report_ablum_button button"
      @click="deleteUploadedPatternMap()"
    >
      {{ $t("term.crackPatternMapDelete") }}
    </div> -->
    <div
      v-if="!expandAlbum.downloadProcess"
      class="report_ablum_button button"
      @click.stop="downloadAlbum(expandAlbum)"
    >
      {{ $t("button.download") }}
    </div>
    <div v-else-if="expandAlbum.downloadProcess" class="report_downloading">
      {{ $t("button.downloading") }}
      <div
        class="report_download_gage"
        :style="{ width: expandAlbum.downloadPercentage + '%' }"
      ></div>
    </div>
    <div
      v-if="
        (!expandAlbum.crackRecognition ||
          expandAlbum.crackRecognition.status == 'Failed') &&
        expandAlbum.photoCount != 0
      "
      class="report_analysis_button button"
      @click.stop="clickAnalysis(expandAlbum)"
    >
      {{ $t("button.analyze") }}
    </div>
    <div
      class="report_ablum_button button"
      @click.stop="deleteAlbum(expandAlbum)"
    >
      {{ $t("button.delete") }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SSE_Download from "@/module/Communication/SSE_Download.js";

export default {
  computed: {
    ...mapState(["user", "selectedFacility", "selectedFacilityMap", "api"]),
    getStatus() {
      if (!this.expandAlbum?.crackRecognition) {
        return this.$t("term.unanalyzed");
      } else if (this.expandAlbum.crackRecognition.status == "Completed") {
        return this.$t("term.done");
      } else if (this.expandAlbum.crackRecognition.status == "Running") {
        return this.$t("term.ongoing");
      } else {
        return this.$t("term.error");
      }
    },
  },
  emits: [
    "openUploadModal",
    "openUploadPatternModal",
    "selectAlbum",
    "unSelectAlbum",
    "deleteAlbum",
    "expandMapMenu",
    "clickAnalysis",
    "setSelectedAlbumInfo",
    "handleClickDeletePatternMap",
  ],
  data() {
    return {
      ellipsisModalState: false,
      expandAlbum: null,
    };
  },
  watch: {
    albumList: {
      handler() {
        this.expandAlbum = null;
      },
    },
  },
  props: {
    uploadState: Boolean,
    isUploadPatternModalOpen: Boolean,
    albumList: Array,
    selectedAlbumList: Array,
    ableToDelete3DModelFile: Boolean,
    selectedAlbumInfo: Object,
  },
  methods: {
    checkSelectedAlbumList(albumId) {
      let findAblum = this.selectedAlbumList.find(
        (ablum) => ablum.id == albumId
      );
      return findAblum ? true : false;
    },
    openUploadModal() {
      if (this.selectedFacilityMap.id) {
        if (this.albumList.length < 100) {
          this.$emit("openUploadModal");
        } else {
          this.$store.commit(
            "openAlert",
            `${this.$t("albumAlert.limitedAlbumsPerMapCanRegistered", {
              limit: 100,
            })}\n${this.$t("albumAlert.deleteExistingAlbumForAdditionalAlbum")}`
          );
        }
      } else {
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.selectMapToUpload")
        );
      }
    },
    selectAlbum(album) {
      let check = this.checkSelectedAlbumList(album.id);
      if (check) {
        this.$emit("unSelectAlbum", album);
      } else {
        this.$emit("selectAlbum", album);
      }
    },
    openUploadPatternModal(boolean) {
      this.$emit("openUploadPatternModal", boolean);
      this.$emit("setSelectedAlbumInfo", this.expandAlbum);
    },
    expandAlbumMenu(album) {
      if (album == this.expandAlbum) {
        this.expandAlbum = null;
      } else {
        this.$emit("expandMapMenu");
        this.expandAlbum = album;
        this.$emit("setSelectedAlbumInfo", album);
      }
    },
    clickAnalysis(album) {
      if (this.user.role === "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        this.$emit("clickAnalysis", album);
      }
    },
    downloadAlbum(album) {
      if (confirm(this.$t("albumAlert.reallyDownloadAlbum"))) {
        album.downloadProcess = true;
        this.api.downloadAlbum(this.downloadAlbumCallback, album, this.user.id);
      } else {
        return false;
      }
    },
    downloadAlbumCallback(album, data) {
      let result = data.data.result;
      this.sse_download = new SSE_Download(
        `${this.api.url}${result.connect_url}`,
        album,
        this.sse_processcallback,
        this.sse_downloadCallback
      );
    },
    sse_processcallback(downloadAlbum, progress) {
      let findedAlbum = this.albumList.find(
        (album) => album.id == downloadAlbum.id
      );
      if (findedAlbum) {
        findedAlbum.downloadProcess = true;
        findedAlbum.downloadPercentage = progress;
      }
    },
    sse_downloadCallback(success, downloadAlbum, data) {
      let findedAlbum = this.albumList.find(
        (album) => album.id == downloadAlbum.id
      );
      if (findedAlbum) {
        findedAlbum.downloadProcess = false;
        findedAlbum.downloadPercentage = 0;
      }
      if (success) {
        this.$store.commit("setDownloading", true);
        let fileUrl = data;
        let link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", "album.zip");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.$store.commit("setDownloading", false);
      } else {
        this.$store.commit(
          "openAlert",
          `${this.$t("albumAlert.photoDownloadFailed")}\n${this.$t(
            "albumAlert.pleaseContactAdmin"
          )}`
        );
      }
    },
    routerPattern(albumId, modelId) {
      this.$router.push({
        name: "pattern",
        params: {
          facilityId: this.selectedFacility.id,
          mapId: this.selectedFacilityMap.id,
          albumId: albumId,
          modelId: modelId,
        },
      });
    },
    deleteAlbum(album) {
      if (this.user.role == "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else if (album.crackRecognition?.status == "Running") {
        this.$store.commit(
          "openAlert",
          this.$t("albumAlert.analyzingAlbumDelete")
        );
      } else {
        if (confirm(this.$t("albumAlert.reallyDeleteAlbum"))) {
          this.$emit("deleteAlbum", album.id);
        } else {
          return false;
        }
      }
    },
    deleteUploadedPatternMap() {
      if (this.user.role === "normal") {
        this.$store.commit(
          "openAlert",
          this.$t("otherAlert.unauthorizedPleaseContactAdmin")
        );
      } else {
        if (confirm(this.$t("albumAlert.reallyDeletePattern"))) {
          this.$emit("handleClickDeletePatternMap", this.expandAlbum);
        } else {
          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.report_list_container {
  height: calc((100% - 80px) / 2);
  width: 100%;
  display: flex;
  flex-direction: column;
}
.report_list_wrap {
  display: flex;
  height: calc(100% / 8);
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  margin-bottom: 10px;
}
.report_list_title {
  display: flex;
  align-items: center;
  width: 100px;
  height: 100%;
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
}
.report_list_add_button {
  width: 30px;
  height: 30px;
  background-image: url("@/../public/images/circlePlus.svg");
  background-size: 15px 15px;
}
.report_list_add_button.on {
  background-image: url("@/../public/images/circlePlus_hover.svg");
  background-size: 15px 15px;
}
.report_list_add_button:hover {
  background-image: url("@/../public/images/circlePlus_hover.svg");
  background-size: 15px 15px;
}
.report_item_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2px;
}
.report_album_item {
  display: flex;
  flex-shrink: 0;
  width: calc(100% - 4px);
  height: 40px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: $main_white;
  font-size: 1.6rem;
  margin: 1px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.report_item {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 70px;
  align-items: center;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  font-size: 1.6rem;
  color: $main_white;
  cursor: pointer;
  margin-bottom: 10px;
}
.report_item:last-of-type() {
  margin-bottom: 0px;
}
.report_item:hover {
  border: 1px solid $main_color;
  color: $main_color;
}
.report_item.active {
  border: 1px solid $main_color;
  color: $main_color;
}
.report_item_top_wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-left: 10px;
}
.report_item_check_box {
  height: 20px;
  width: 20px;
  border-radius: 3px;
  box-shadow: $main_box_shadow;
  background-color: rgb(32, 32, 32);
  cursor: pointer;
}
.report_item_check_box.on {
  background-image: url("@/../public/images/check.svg");
  background-size: 15px, 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: $main_color;
}
.report_item_name {
  padding-left: 10px;
  width: calc(100% - 65px);
}
.report_item_button_wrap {
  display: flex;
  width: 100%;
  height: 50%;
  padding-right: 10px;
  justify-content: flex-end;
  align-items: center;
}
.report_ablum_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.report_downloading {
  position: relative;
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: $main_box_shadow;
  color: rgba(1, 250, 254, 1);
  background-color: #171717;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.report_download_gage {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 0%;
  height: 100%;
  background-color: rgba(1, 250, 254, 0.6);
}
.report_analysis_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.report_analysis_completed_button {
  @include flexbox;
  width: 100%;
  height: 30px;
  font-size: 1.5rem;
}
.report_analysis_completed_button:hover {
  background-color: #171717;
  color: rgba(1, 250, 254, 1);
  cursor: default;
}
.report_ablum_expand_menu_modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: calc(100% / 4 + 25px);
  top: calc(100% / 16 * 9 + 71px);
  width: 150px;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
  z-index: 6;
}
@media (min-width: 1250px) {
  .report_ablum_expand_menu_modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    left: 335px;
    top: calc(100% / 16 * 9 + 71px);
    width: 150px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    z-index: 6;
  }
}
.report_ablum_ananlysis_state {
  @include flexbox;
  width: 100%;
  height: 40px;
  color: $main_white;
  font-size: 1.8rem;
}
</style>
