import CameraMission from '@/model/CameraMission';
import Waypoint from '@/model/Waypoint.js'

export default class GroupHandler {
    constructor(waypointList, groupList) {
        this.waypointList = waypointList;
        this.groupList = groupList;
    }

    reset(currentDroneId, setFalse){        
        for(let i=0;i<this.groupList[currentDroneId].length;i++){
            if(setFalse) this.groupList[currentDroneId][i].setChecked(false);
        }
    }

    delete(currentDroneId){
        for(let i=this.groupList[currentDroneId].length-1;i>=0;i--){
            if(this.groupList[currentDroneId][i].getChecked()){
                for(let j=this.waypointList[currentDroneId].length-1;j>=0;j--){
                if(this.waypointList[currentDroneId][j].getGroupNum() === i+1){
                    this.waypointList[currentDroneId].splice(j,1);
                }
                else if(this.waypointList[currentDroneId][j].getGroupNum() >= i+1){
                    this.waypointList[currentDroneId][j].setGroupNum(this.waypointList[currentDroneId][j].getGroupNum()-1);
                }
                }
                for(let j=0;j<this.groupList[currentDroneId].length;j++){
                if(this.groupList[currentDroneId][j].getGroupNum() > this.groupList[currentDroneId][i].getGroupNum())
                    this.groupList[currentDroneId][j].setGroupNum(this.groupList[currentDroneId][j].getGroupNum()-1);
                }
                this.groupList[currentDroneId].splice(i,1);
            }
            }
    }

    swap(currentDroneId, swapIdx){
        let temp = this.groupList[currentDroneId][swapIdx[0]];
        this.groupList[currentDroneId][swapIdx[0]] = this.groupList[currentDroneId][swapIdx[1]]
        this.groupList[currentDroneId][swapIdx[1]] = temp;

        this.groupList[currentDroneId][swapIdx[0]].setGroupNum(swapIdx[0]+1);
        this.groupList[currentDroneId][swapIdx[1]].setGroupNum(swapIdx[1]+1);

        let groupNum1 = swapIdx[0]+1;
        let groupNum2 = swapIdx[1]+1;

        let group1 = this.waypointList[currentDroneId].filter(wp => wp.getGroupNum() === groupNum1);
        let group2 = this.waypointList[currentDroneId].filter(wp => wp.getGroupNum() === groupNum2);
        let otherGroups = this.waypointList[currentDroneId].filter(wp => wp.getGroupNum() !== groupNum1 && 
                                                                        wp.getGroupNum() !== groupNum2);
        
        group1.forEach(wp => wp.setGroupNum(groupNum2));
        group2.forEach(wp => wp.setGroupNum(groupNum1));

        this.waypointList[currentDroneId] = [
            ...otherGroups.filter(wp => wp.getGroupNum() < groupNum1), // groupNum1 이전의 그룹들
            ...groupNum1 < groupNum2 ? group2 : group1, // 더 작은 번호의 그룹
            ...otherGroups.filter(wp => wp.getGroupNum() > groupNum1 && wp.getGroupNum() < groupNum2), // 두 그룹 사이의 그룹들
            ...groupNum1 < groupNum2 ? group1 : group2, // 더 큰 번호의 그룹
            ...otherGroups.filter(wp => wp.getGroupNum() > groupNum2) // groupNum2 이후의 그룹들
        ];
    }

    rotateCircle(currentDroneId, idx, gimbalControl){
        let group = this.groupList[currentDroneId][idx];

        let shape = group.getShape();
        let groupNum = group.getGroupNum();    
        let dTheta = group.getInterval();
        let fromWall = group.getFromWall();        
        let inward = group.getCircleInward();
        let startAngle = group.getCircleStartAngle();
        let coeffs = group.getCircleCoeffs();
        let lowerHeight = parseFloat(group.getLowerHeight());
        let upperHeight = parseFloat(group.getUpperHeight());
        let initDeg = group.rotateCircle(5);

        let degCounter = 0;
        let rotatedWaypoints = [];
    
        let coeff = coeffs[0];

        for(let degIdx=startAngle; degIdx<=360; degIdx+=dTheta){
            let theta = initDeg - degIdx;
            if(theta<0) theta += 360;
    
            let posX = (coeff.radius + fromWall)*Math.cos(Math.PI/180.0*(theta)) + coeff.x;
            let posY = (coeff.radius + fromWall)*Math.sin(Math.PI/180.0*(theta)) + coeff.y;
            let yaw;
            if(!inward) yaw = -((theta+180.0)%360);
            else yaw = -(theta%360); 
    
            if(degCounter%2 ==0){
                let gimbalPitchArray = gimbalControl? [90,60,30,0] : null;
                let gimbalPitch;
                if(upperHeight != lowerHeight && gimbalControl){
                    if(degCounter == 0){
                        gimbalPitch = gimbalPitchArray[3];    
                    }
                    else{
                        gimbalPitch = gimbalPitchArray[0];
                    }
                }
                else{
                    gimbalPitch = 0;
                }
                let cameraMission = new CameraMission({gimbalPitch : gimbalPitchArray, shouldCapture : true})
                let waypoint = new Waypoint(posX, posY, upperHeight, yaw/180.0*Math.PI, 
                                            gimbalPitch, shape, true, false, groupNum, cameraMission);
                rotatedWaypoints.push(waypoint);
                gimbalPitchArray = gimbalControl? [0,-30,-60,-90] : null;
                if(upperHeight != lowerHeight){
                    cameraMission = new CameraMission({gimbalPitch : gimbalPitchArray, shouldCapture : true})
                    waypoint = new Waypoint(posX, posY, lowerHeight, yaw/180.0*Math.PI, 
                                            gimbalControl? gimbalPitchArray[0] : 0, shape, true, false, groupNum, cameraMission);                        
                    rotatedWaypoints.push(waypoint); 
                }
            }else{
                let gimbalPitchArray;
                gimbalPitchArray =  gimbalControl? [-90,-60,-30,0] : null;
                let gimbalPitch;
                if(upperHeight != lowerHeight && gimbalControl){
                    gimbalPitch = gimbalPitchArray[0];
                }
                else{
                    gimbalPitch = 0;
                }
                let cameraMission = new CameraMission({gimbalPitch : gimbalPitchArray, shouldCapture : true})
                let waypoint = new Waypoint(posX, posY, lowerHeight, yaw/180.0*Math.PI, 
                                            gimbalPitch, shape, true, false, groupNum, cameraMission);                
                rotatedWaypoints.push(waypoint); 
                gimbalPitchArray = gimbalControl? [0,30,60,90] : null;
                if(upperHeight != lowerHeight){
                    cameraMission = new CameraMission({gimbalPitch : gimbalPitchArray, shouldCapture : true})
                    waypoint = new Waypoint(posX, posY, upperHeight, yaw/180.0*Math.PI, 
                                            gimbalControl? gimbalPitchArray[0] : 0, shape, true, false, groupNum, cameraMission);   
                    rotatedWaypoints.push(waypoint); 
                }
            }
            degCounter++;
        }
        rotatedWaypoints[rotatedWaypoints.length-1].mission.shouldCapture = false;

        let otherGroups = this.waypointList[currentDroneId].filter(wp => wp.getGroupNum() !== group.getGroupNum());

        this.waypointList[currentDroneId] = [
            ...otherGroups.filter(wp => wp.getGroupNum() < group.getGroupNum()),
            ...rotatedWaypoints,
            ...otherGroups.filter(wp => wp.getGroupNum() > group.getGroupNum())
        ];
    }

    divideLine(firstPoint, secondPoint, interval){
        let dividedPoints = [];  
        dividedPoints.push({x: firstPoint.x, y:firstPoint.y});
        
        let length = interval;
        let currentSegmentLength = Math.sqrt(Math.pow(secondPoint.x-firstPoint.x,2)+Math.pow(secondPoint.y-firstPoint.y,2));
        
        while(length < currentSegmentLength) {
            let t = length / currentSegmentLength;
            let x = (1 - t) * firstPoint.x + t * secondPoint.x;
            let y = (1 - t) * firstPoint.y + t * secondPoint.y;
    
            dividedPoints.push({x:x, y:y});
    
            if((currentSegmentLength - length) > 0.5 && (currentSegmentLength - length) <= interval){
                // dividedPoints.push({x:secondPoint.x, y:secondPoint.y});
                dividedPoints[dividedPoints.length-1] = {x:secondPoint.x, y:secondPoint.y};
            }
            length += interval;            
        }

        if(dividedPoints.length ==1) dividedPoints.push({x: secondPoint.x, y:secondPoint.y});
    
        return dividedPoints;
    }

    rotateRectangle(currentDroneId, idx){       
        let group = this.groupList[currentDroneId][idx];
        let waypoints = this.waypointList[currentDroneId].filter(waypoint => waypoint.groupNum == group.group_num);
        let slice_waypoints = waypoints.slice(0,(waypoints.length)/4);
        slice_waypoints[slice_waypoints.length - 1].mission.shouldCapture = false;
        let rest_waypoints = waypoints.slice((waypoints.length)/4, waypoints.length);
        rest_waypoints[rest_waypoints.length - 1].mission.shouldCapture = true;
        let new_waypoints = rest_waypoints.concat(slice_waypoints);

        group.rotateRect(1);

        let otherGroups = this.waypointList[currentDroneId].filter(wp => wp.getGroupNum() !== group.group_num);
      
        this.waypointList[currentDroneId] = [
          ...otherGroups.filter(wp => wp.getGroupNum() < group.group_num),
          ...new_waypoints,
          ...otherGroups.filter(wp => wp.getGroupNum() > group.group_num)
        ];
    }

    rotate(currentDroneId){
        let checkedIdx=[];        
        for(let i=0;i<this.groupList[currentDroneId].length;i++) {            
            if(this.groupList[currentDroneId][i].getChecked()) 
            {               
                checkedIdx.push(i);
                if(this.groupList[currentDroneId][i].getShape() == "Line") return false;
                if(this.groupList[currentDroneId][i].getShape() == "Underside") return false;
                if(this.groupList[currentDroneId][i].getShape() == "Merged Circle") return false;
                if(this.groupList[currentDroneId][i].getShape() == "Merged Rectangle") return false;
            }
        }        
        if(checkedIdx.length <1) return false;        

        let gimbal_control;
        if(this.waypointList[currentDroneId][1].mission.gimbalPitch) gimbal_control = true;
        else gimbal_control = false;
        for(let i=0;i<checkedIdx.length;i++) {            
            if(this.groupList[currentDroneId][checkedIdx[i]].getShape() === "Circle"){                
                this.rotateCircle(currentDroneId, checkedIdx[i], gimbal_control);
            }
            else if(this.groupList[currentDroneId][checkedIdx[i]].getShape() === "Rectangle"){
                this.rotateRectangle(currentDroneId, checkedIdx[i]);
            }
          }
              
         return true;
    }

    mergeCircle(currentDroneId, checkedIdx){
        let initDeg = 0;
        let lowerHeight = parseFloat(this.groupList[currentDroneId][checkedIdx[0]].getLowerHeight());
        let upperHeight = parseFloat(this.groupList[currentDroneId][checkedIdx[0]].getUpperHeight());        
        let interval = this.groupList[currentDroneId][checkedIdx[0]].getInterval();
        let inward   = this.groupList[currentDroneId][checkedIdx[0]].getCircleInward();
        let groupNum = this.groupList[currentDroneId][checkedIdx[0]].getGroupNum();
        let directionCounter = 0;

        let mergedWaypoints = [];

        let coeffs =[]
        for(let i =0;i<checkedIdx.length; i++){
            coeffs.push(this.groupList[currentDroneId][checkedIdx[i]].getCircleCoeffs()[0]);
        }

        coeffs.sort((a, b) => a.x - b.x);

        initDeg = Math.atan((coeffs[coeffs.length-1].y-coeffs[0].y)/(coeffs[coeffs.length-1].x-coeffs[0].x));
        initDeg = initDeg/Math.PI*180.0+90.0;

        let firstEndPoint = {x:0, y:0, deg:0};
        let theta;   
        let pitchDown = true;

        for(let j=360; j>=180; j-=interval){
            theta = initDeg - j;
            if(theta<0) theta+=360;
    
            let posX = (coeffs[0].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                    Math.cos(Math.PI/180.0*(theta))+coeffs[0].x;
            let posY = (coeffs[0].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                    Math.sin(Math.PI/180.0*(theta))+coeffs[0].y;
            
            let yaw;
            if(!inward) 
                yaw = -((theta+180.0)%360);
            else yaw = -(theta%360);
    
            let FirstZ, SecondZ;
            let firstPitch, secondPitch;
            if(directionCounter%2==0){
                FirstZ = upperHeight;
                SecondZ = lowerHeight;
                firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
            } 
            else{
                FirstZ = lowerHeight;
                SecondZ = upperHeight;
                firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
            }
    
            let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true})
            let waypoint;
            if(directionCounter == 0){
                waypoint = new Waypoint(posX, posY, FirstZ, yaw/180.0*Math.PI, firstPitch[3], "Merged Circle", true, false, groupNum, cameraMission);            
            }
            else{
                waypoint = new Waypoint(posX, posY, FirstZ, yaw/180.0*Math.PI, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);            
            }
            mergedWaypoints.push(waypoint);
    
            if(upperHeight!=lowerHeight){
                cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true})
                waypoint = new Waypoint(posX, posY, SecondZ, yaw/180.0*Math.PI, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);                
                mergedWaypoints.push(waypoint);
            }
        
            if(j===180){
                firstEndPoint.x = posX;
                firstEndPoint.y = posY;
                firstEndPoint.theta  = theta;
            }
            pitchDown = !pitchDown
            directionCounter++;
        }

        for(let j=180; j>=0; j-=interval){
            theta = initDeg - j;
            if(theta<0) theta+=360;
            let posX = (coeffs[coeffs.length-1].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                    Math.cos(Math.PI/180.0*(theta))+coeffs[coeffs.length-1].x;
            let posY = (coeffs[coeffs.length-1].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                    Math.sin(Math.PI/180.0*(theta))+coeffs[coeffs.length-1].y;
            
            let yaw;
            if(!inward) yaw = -((theta+180.0)%360);
            else yaw = -(theta%360);
    
            if(j==180 && inward){
                let temp_posX, temp_posY;
                for(let k=1;k<coeffs.length;k++){
                    temp_posX = (coeffs[k].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                                Math.cos(Math.PI/180.0*(theta))+coeffs[k].x;
                    temp_posY = (coeffs[k].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                                Math.sin(Math.PI/180.0*(theta))+coeffs[k].y;
    
                    let tempPointX = (firstEndPoint.x + temp_posX)/2.0
                    let tempPointY = (firstEndPoint.y + temp_posY)/2.0
    
                    let dx = tempPointX - coeffs[k-1].x ;
                    let dy = tempPointY - coeffs[k-1].y;
                    let tempYaw = -Math.atan2(dy, dx);
    
                    let tempFirstZ, tempSecondZ;
                    let firstPitch, secondPitch;
                    if(directionCounter%2==0){
                        tempFirstZ = upperHeight;
                        tempSecondZ = lowerHeight;
                        firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                        secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                    } 
                    else{
                        tempFirstZ = lowerHeight;
                        tempSecondZ = upperHeight;
                        firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90]; 
                    }
                    let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    let waypoint = new Waypoint(tempPointX, tempPointY, tempFirstZ, tempYaw, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
    
                    if(upperHeight!=lowerHeight){
                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, tempSecondZ, tempYaw, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    if(directionCounter%2!=0){
                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, upperHeight, yaw/180.0*Math.PI, 90, "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    pitchDown = !pitchDown
                    directionCounter++;

    
                    if(directionCounter%2==0){
                        tempFirstZ = upperHeight;
                        tempSecondZ = lowerHeight;
                        firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                        secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                    } 
                    else{
                        tempFirstZ = lowerHeight;
                        tempSecondZ = upperHeight;
                        firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90]; 
                    }
    
                    dx = tempPointX - coeffs[k].x;
                    dy = tempPointY - coeffs[k].y;
                    tempYaw = -Math.atan2(dy, dx);
                    
                    cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    waypoint = new Waypoint(tempPointX, tempPointY, tempFirstZ, tempYaw, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
    
                    if(upperHeight!=lowerHeight){
                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, tempSecondZ, tempYaw, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    if(directionCounter%2!=0){
                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, upperHeight, yaw/180.0*Math.PI, 90, "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    pitchDown = !pitchDown
                    directionCounter++
    
                    if(k!=coeffs.length-1){
                        if(directionCounter%2==0){
                            tempFirstZ = upperHeight;
                            tempSecondZ = lowerHeight;
                            firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                            secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        } 
                        else{
                            tempFirstZ = lowerHeight;
                            tempSecondZ = upperHeight;
                            firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                            secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90]; 
                        }
                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        waypoint = new Waypoint(temp_posX, temp_posY, tempFirstZ, yaw/180.0*Math.PI, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
    
                        if(upperHeight!=lowerHeight){
                            cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                            waypoint = new Waypoint(temp_posX, temp_posY, tempSecondZ, yaw/180.0*Math.PI, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                            mergedWaypoints.push(waypoint);
                        }
                        pitchDown = !pitchDown
                        directionCounter++
                    }
                    firstEndPoint.x = temp_posX;
                    firstEndPoint.y = temp_posY;
                }
            }
           
            let FirstZ, SecondZ;
            let firstPitch, secondPitch;
            if(directionCounter%2==0){
                FirstZ = upperHeight;
                SecondZ = lowerHeight;
                firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
            } 
            else{
                FirstZ = lowerHeight;
                SecondZ = upperHeight;
                firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90]; 
            }
            let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true})
            let waypoint = new Waypoint(posX, posY, FirstZ, yaw/180.0*Math.PI,  firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);            
            mergedWaypoints.push(waypoint);
    
            if(upperHeight!=lowerHeight){
                cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true})
                waypoint = new Waypoint(posX, posY, SecondZ, yaw/180.0*Math.PI, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);            
                mergedWaypoints.push(waypoint);
            }
    
            pitchDown = !pitchDown
            directionCounter++;
    
            if(j==0 && inward){
                let temp_posX, temp_posY;
                let ref_pos = {x: posX, y: posY}
                for(let k=coeffs.length-2;k>=0;k--){
                    temp_posX = (coeffs[k].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                                Math.cos(Math.PI/180.0*(theta))+coeffs[k].x;
                    temp_posY = (coeffs[k].radius + this.groupList[currentDroneId][checkedIdx[0]].getFromWall())*
                                Math.sin(Math.PI/180.0*(theta))+coeffs[k].y;
    
                    let tempPointX = (ref_pos.x + temp_posX)/2.0;
                    let tempPointY = (ref_pos.y + temp_posY)/2.0;
    
                    let dx = tempPointX - coeffs[k+1].x ;
                    let dy = tempPointY - coeffs[k+1].y;
                    let tempYaw = -Math.atan2(dy, dx);
    
                    let tempFirstZ, tempSecondZ;
                    let firstPitch, secondPitch;
                    if(directionCounter%2==0){
                        tempFirstZ = upperHeight;
                        tempSecondZ = lowerHeight;
                        firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                        secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                    } 
                    else{
                        tempFirstZ = lowerHeight;
                        tempSecondZ = upperHeight;
                        firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                    }
                    let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    let waypoint = new Waypoint(tempPointX, tempPointY, tempFirstZ, tempYaw, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                    
                    if(upperHeight!=lowerHeight){
                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, tempSecondZ, tempYaw, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);                            
                        mergedWaypoints.push(waypoint);
                    }

                    if(directionCounter%2!=0){
                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, upperHeight, yaw/180.0*Math.PI, 90, "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    pitchDown = !pitchDown
                    directionCounter++
    
                    if(directionCounter%2==0){
                        tempFirstZ = upperHeight;
                        tempSecondZ = lowerHeight;
                        firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                        secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                    } 
                    else{
                        tempFirstZ = lowerHeight;
                        tempSecondZ = upperHeight;
                        firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                    }
    
                    dx = tempPointX - coeffs[k].x;
                    dy = tempPointY - coeffs[k].y;
                    tempYaw = -Math.atan2(dy, dx);
                    cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    waypoint = new Waypoint(tempPointX, tempPointY, tempFirstZ, tempYaw, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                    
                    if(upperHeight!=lowerHeight){
                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, tempSecondZ, tempYaw, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    if(directionCounter%2!=0){
                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(tempPointX, tempPointY, upperHeight, yaw/180.0*Math.PI, 90, "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    directionCounter++;
    
                    if(k!=0){   
                        if(directionCounter%2==0){
                            tempFirstZ = upperHeight;
                            tempSecondZ = lowerHeight;
                            firstPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                            secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                        } 
                        else{
                            tempFirstZ = lowerHeight;
                            tempSecondZ = upperHeight;
                            firstPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];
                            secondPitch = pitchDown? [90,60,30,0]:[0,30,60,90];
                        }
                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true})
                        waypoint = new Waypoint(temp_posX, temp_posY, tempFirstZ, yaw/180.0*Math.PI, firstPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                        
                        if(upperHeight!=lowerHeight){
                            cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true})
                            waypoint = new Waypoint(temp_posX, temp_posY, tempSecondZ, yaw/180.0*Math.PI, secondPitch[0], "Merged Circle", true, false, groupNum, cameraMission);
                            mergedWaypoints.push(waypoint);
                        }
                        directionCounter++;
                    }
    
                    ref_pos.x = temp_posX;
                    ref_pos.y = temp_posY;
                }                                               
            }      
        }
        mergedWaypoints[mergedWaypoints.length-1].mission.shouldCapture = false;

        let otherGroups = this.waypointList[currentDroneId].filter(
            wp => !checkedIdx.some(idx => 
                wp.getGroupNum() === this.groupList[currentDroneId][idx].getGroupNum())
        );
    
        this.waypointList[currentDroneId] = [
            ...otherGroups.filter(wp => wp.getGroupNum() < this.groupList[currentDroneId][checkedIdx[0]].getGroupNum()),
            ...mergedWaypoints,
            ...otherGroups.filter(wp => wp.getGroupNum() > this.groupList[currentDroneId][checkedIdx[0]].getGroupNum())
        ];
    
        this.groupList[currentDroneId][checkedIdx[0]].setShape("Merged Circle");   
        
        for(let j=this.waypointList[currentDroneId].length-1;j>=0;j--)
        {
            if(this.waypointList[currentDroneId][j].getGroupNum() >=  
               this.groupList[currentDroneId][checkedIdx[checkedIdx.length-1]].getGroupNum())
            {
                this.waypointList[currentDroneId][j].setGroupNum(this.waypointList[currentDroneId][j].getGroupNum() - (checkedIdx.length-1));
            }
        }
        for(let j=0;j<this.groupList[currentDroneId].length;j++)
        {
            if(this.groupList[currentDroneId][j].getGroupNum() > this.groupList[currentDroneId][checkedIdx[checkedIdx.length-1]].getGroupNum()){
                this.groupList[currentDroneId][j].setGroupNum(this.groupList[currentDroneId][j].getGroupNum()-(checkedIdx.length-1));
            }
        }
        for(let j = checkedIdx.length-1;j>0;j--){
            this.groupList[currentDroneId].splice(this.groupList[currentDroneId][checkedIdx[j]].getGroupNum()-1,1); 
        }
    }

    getCenterPoint(points){
        let xMin =  10000000;
        let xMax = -10000000;
        let yMin =  10000000;
        let yMax = -10000000;
        let xAvg, yAvg;
        for(let point of points){
            xMin = Math.min(xMin, point.x);
            xMax = Math.max(xMax, point.x);
            yMin = Math.min(yMin, point.y);
            yMax = Math.max(yMax, point.y);
        }
        xAvg = (xMin+xMax)/2.0;
        yAvg = (yMin+yMax)/2.0;
        let centerPoint ={
            x: xAvg, 
            y: yAvg
        };
        return centerPoint  
    }

    getOrderedPoints(centerPoint, points, targetDeg){    
        let diff90min=1000;
        let idx4Order=0;
        let min90idx;
                
        for(let i=0; i<points.length; i++){
            let deg = Math.atan2(points[i].y - centerPoint.y, points[i].x- centerPoint.x)/Math.PI*180.0; 
            if(diff90min>Math.abs(deg-targetDeg+100)){
                diff90min = Math.abs(deg-targetDeg+100);
                min90idx = idx4Order;            
            }
            idx4Order++;
        }
    
        let firstOrderedPointsRots = [];
        for(let i=0;i<4;i++){
            firstOrderedPointsRots.push({
                    x:points[(min90idx+i)%4].x,
                    y:points[(min90idx+i)%4].y,
                    fit_x:points[(min90idx+i)%4].fit_x,
                    fit_y:points[(min90idx+i)%4].fit_y,
                    rot:points[(min90idx+i)%4].rot
                }
            )
        }
        return firstOrderedPointsRots;
    }

    getMergedPoints(centerList, orderedPointList, targetDeg){             
        let mergedPointsRots = [];
        const indices = Array.from(centerList.keys());
        if(targetDeg>45 && targetDeg<135){
            indices.sort((a, b) => centerList[a].y - centerList[b].y);
            centerList.sort((a, b) => a.y - b.y);
        } else if(targetDeg<=45){
            indices.sort((a, b) => centerList[a].x - centerList[b].x);
            centerList.sort((a, b) => a.x - b.x);
        } else if(targetDeg>=135){
            indices.sort((a, b) => centerList[b].x - centerList[a].x);
            centerList.sort((a, b) => b.x - a.x);
        }
        const sortedOrderedPointList = indices.map(index => orderedPointList[index]);
        
        for(let i= 0; i<sortedOrderedPointList.length; i++){
            mergedPointsRots.push(sortedOrderedPointList[i][0]);            
            mergedPointsRots.push(sortedOrderedPointList[i][1]);
        }

        mergedPointsRots.push(sortedOrderedPointList[sortedOrderedPointList.length-1][1]);
        mergedPointsRots.push(sortedOrderedPointList[sortedOrderedPointList.length-1][2]);

        for(let i=sortedOrderedPointList.length-1; i>=0; i--){
            mergedPointsRots.push(sortedOrderedPointList[i][2]);
            mergedPointsRots.push(sortedOrderedPointList[i][3]);
        }

        mergedPointsRots.push(sortedOrderedPointList[0][3]);
        mergedPointsRots.push(sortedOrderedPointList[0][0]);

        return mergedPointsRots;
    }

    mergeRectangle(currentDroneId, checkedIdx){
        let lowerHeight  = parseFloat(this.groupList[currentDroneId][checkedIdx[0]].getLowerHeight());
        let upperHeight  = parseFloat(this.groupList[currentDroneId][checkedIdx[0]].getUpperHeight());
        let interval = this.groupList[currentDroneId][checkedIdx[0]].getInterval();
        let inward   = this.groupList[currentDroneId][checkedIdx[0]].getRectInward();
        let groupNum = this.groupList[currentDroneId][checkedIdx[0]].getGroupNum();

        let count = 0;
        let pointList = [];
        let coeffList = [];
        let centerList = [];
        let orderedPointList = [];
        let mergedWaypoints = [];

        let pitchDown = true;

        for(let i=0; i<checkedIdx.length; i++){
            pointList.push(this.groupList[currentDroneId][checkedIdx[i]].getRectCoeffs()[0]);
        }
        for(let i=0; i<pointList.length; i++){
            let coeff =[]; 
            coeff.push({x: pointList[i].point1_x, y: pointList[i].point1_y, 
                        fit_x: pointList[i].fit_point1_x, fit_y: pointList[i].fit_point1_y, rot: pointList[i].rot +  90 +180});
            coeff.push({x: pointList[i].point2_x, y: pointList[i].point2_y, 
                        fit_x: pointList[i].fit_point2_x, fit_y: pointList[i].fit_point2_y, rot: pointList[i].rot + 180 +180});
            coeff.push({x: pointList[i].point3_x, y: pointList[i].point3_y, 
                        fit_x: pointList[i].fit_point3_x, fit_y: pointList[i].fit_point3_y, rot: pointList[i].rot + 270 +180});
            coeff.push({x: pointList[i].point4_x, y: pointList[i].point4_y, 
                        fit_x: pointList[i].fit_point4_x, fit_y: pointList[i].fit_point4_y, rot: pointList[i].rot + 180});
            coeffList.push(coeff);
        }

        for(let i=0; i<coeffList.length; i++){
            centerList.push(this.getCenterPoint(coeffList[i]));
        }


        let tempDeg = Math.atan2(centerList[0].y - centerList[centerList.length-1].y, centerList[0].x - centerList[centerList.length-1].x)/Math.PI*180.0;
        if(tempDeg<0) tempDeg += 180;
        for(let i=0; i<centerList.length; i++){            
            orderedPointList.push(this.getOrderedPoints(centerList[i], coeffList[i], tempDeg));            
        }
        let mergedPoints = this.getMergedPoints(centerList, orderedPointList, tempDeg);

        let surfCount = 1;
       
        for(let i=0;i<mergedPoints.length;i+=2){
            const fitLength = Math.sqrt(Math.pow(mergedPoints[i].fit_x-mergedPoints[(i+1)%mergedPoints.length].fit_x,2)+
                                        Math.pow(mergedPoints[i].fit_y-mergedPoints[(i+1)%mergedPoints.length].fit_y,2));
            const pointLength =  Math.sqrt(Math.pow(mergedPoints[i].x-mergedPoints[(i+1)%mergedPoints.length].x,2)+
                                           Math.pow(mergedPoints[i].y-mergedPoints[(i+1)%mergedPoints.length].y,2));

            let t = ((pointLength-fitLength)/2) / pointLength;
            const firstPoint = {x: (1 - t) * mergedPoints[i].x + t * mergedPoints[(i+1)%mergedPoints.length].x, 
                                y: (1 - t) * mergedPoints[i].y + t * mergedPoints[(i+1)%mergedPoints.length].y};
            t = ((pointLength+fitLength)/2) / pointLength;
            const secondPoint = {x: (1 - t) * mergedPoints[i].x + t * mergedPoints[(i+1)%mergedPoints.length].x, 
                                 y: (1 - t) * mergedPoints[i].y + t * mergedPoints[(i+1)%mergedPoints.length].y};

            let tempWaypoints = this.divideLine(firstPoint, secondPoint, interval);

            if(i==0 || i==checkedIdx.length*2 || i==(checkedIdx.length+1)*2 || i==(checkedIdx.length*2+1)*2){
                let addRot = -45;
                let yaw;
                if(!inward) yaw = -((mergedPoints[i].rot+180+addRot)%360)
                else yaw = -((mergedPoints[i].rot+addRot)%360)
                
                if(count%2==0){
                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    let waypoint;
                    if(count == 0){
                        waypoint = new Waypoint(mergedPoints[i].x, mergedPoints[i].y, upperHeight, yaw/180.0*Math.PI, firstPitch[3], "Merged Rectangle", true, false, groupNum, cameraMission);
                    }
                    else{
                        waypoint = new Waypoint(mergedPoints[i].x, mergedPoints[i].y, upperHeight, yaw/180.0*Math.PI, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    }
                    mergedWaypoints.push(waypoint);

                    cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                    waypoint = new Waypoint(mergedPoints[i].x, mergedPoints[i].y, lowerHeight, yaw/180.0*Math.PI, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                }
                else{
                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                    let waypoint = new Waypoint(mergedPoints[i].x, mergedPoints[i].y, lowerHeight, yaw/180.0*Math.PI, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);

                    cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    waypoint = new Waypoint(mergedPoints[i].x, mergedPoints[i].y, upperHeight, yaw/180.0*Math.PI, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                }
                count++;
                pitchDown = !pitchDown;
            }
            for(let point of tempWaypoints){
                let yaw;
                if(!inward) yaw = -((mergedPoints[i].rot+180)%360)
                else yaw = -(mergedPoints[i].rot%360)
                
                if(count%2==0){
                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    let waypoint = new Waypoint(point.x, point.y, upperHeight, yaw/180.0*Math.PI, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);

                    cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                    waypoint = new Waypoint(point.x, point.y, lowerHeight, yaw/180.0*Math.PI, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                }
                else{
                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                    let waypoint = new Waypoint(point.x, point.y, lowerHeight, yaw/180.0*Math.PI, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);

                    cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                    waypoint = new Waypoint(point.x, point.y, upperHeight, yaw/180.0*Math.PI, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                    mergedWaypoints.push(waypoint);
                }
                count++;
                pitchDown = !pitchDown;
            }

            if(surfCount%(checkedIdx.length+1) != 0  && surfCount%(checkedIdx.length+1) != checkedIdx.length){
                let yaw;
                if(!inward) yaw = -((mergedPoints[i].rot+180)%360)
                else yaw = -(mergedPoints[i].rot%360)
                
                if(surfCount<=checkedIdx.length){
                    let center = {x:(mergedPoints[i+1].x + mergedPoints[i+2].x)/2, y:(mergedPoints[i+1].y + mergedPoints[i+2].y)/2};
                    let target = centerList[surfCount-1];

                    let dx = center.x - target.x;
                    let dy = center.y - target.y;
                    let tempYaw = -Math.atan2(dy, dx);

                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    if(count%2==0){
                        let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    else{
                        let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, yaw/180.0*Math.PI, 90, "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    count++;
                    pitchDown = !pitchDown;
                                
                    center = {x:(mergedPoints[i+1].x + mergedPoints[i+2].x)/2, y:(mergedPoints[i+1].y + mergedPoints[i+2].y)/2};
                    target = centerList[surfCount];

                    dx = center.x - target.x;
                    dy = center.y - target.y;
                    tempYaw = -Math.atan2(dy, dx);

                    firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    if(count%2==0){
                        let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    else{
                        let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, yaw/180.0*Math.PI, 90, "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    
                    count++;
                    pitchDown = !pitchDown;

                }else{
                    let center = {x:(mergedPoints[i+1].x + mergedPoints[i+2].x)/2, y:(mergedPoints[i+1].y + mergedPoints[i+2].y)/2};
                    let target = centerList[Math.abs(surfCount - checkedIdx.length*2 - 1)];

                    let dx = center.x - target.x;
                    let dy = center.y - target.y;
                    let tempYaw = -Math.atan2(dy, dx);

                    let firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    let secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    if(count%2==0){
                        let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    else{
                        let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, yaw/180.0*Math.PI, 90, "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    count++;
                    pitchDown = !pitchDown;
                                
                    center = {x:(mergedPoints[i+1].x + mergedPoints[i+2].x)/2, y:(mergedPoints[i+1].y + mergedPoints[i+2].y)/2};
                    target = centerList[Math.abs(surfCount - checkedIdx.length*2)];

                    dx = center.x - target.x;
                    dy = center.y - target.y;
                    tempYaw = -Math.atan2(dy, dx);

                    firstPitch  = pitchDown? [90,60,30,0]:[0,30,60,90];
                    secondPitch = pitchDown? [0,-30,-60,-90]:[-90,-60,-30,0];

                    if(count%2==0){
                        let cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }
                    else{
                        let cameraMission = new CameraMission({gimbalPitch : secondPitch, shouldCapture : true});
                        let waypoint = new Waypoint(center.x, center.y, lowerHeight, tempYaw, secondPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : firstPitch, shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, tempYaw, firstPitch[0], "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);

                        cameraMission = new CameraMission({gimbalPitch : [90, 60, 30, 0], shouldCapture : true});
                        waypoint = new Waypoint(center.x, center.y, upperHeight, yaw/180.0*Math.PI, 90, "Merged Rectangle", true, false, groupNum, cameraMission);
                        mergedWaypoints.push(waypoint);
                    }

                    count++;
                    pitchDown = !pitchDown;
                                
                }
                
            }

            surfCount++;
        }

        mergedWaypoints[mergedWaypoints.length-1].mission.shouldCapture = false;


        let otherGroups = this.waypointList[currentDroneId].filter(
            wp => !checkedIdx.some(idx => 
                wp.getGroupNum() === this.groupList[currentDroneId][idx].getGroupNum())
        );
        
        this.waypointList[currentDroneId] = [
            ...otherGroups.filter(wp => wp.getGroupNum() < this.groupList[currentDroneId][checkedIdx[0]].getGroupNum()),
            ...mergedWaypoints,
            ...otherGroups.filter(wp => wp.getGroupNum() > this.groupList[currentDroneId][checkedIdx[0]].getGroupNum())
        ];

        this.groupList[currentDroneId][checkedIdx[0]].setShape("Merged Rectangle");   

        for(let j=this.waypointList[currentDroneId].length-1;j>=0;j--)
        {
            if(this.waypointList[currentDroneId][j].getGroupNum() >= this.groupList[currentDroneId][checkedIdx[checkedIdx.length-1]].getGroupNum()){
                this.waypointList[currentDroneId][j].setGroupNum(this.waypointList[currentDroneId][j].getGroupNum() - (checkedIdx.length-1));
            }
        }

        for(let j=0;j<this.groupList[currentDroneId].length;j++){
            if(this.groupList[currentDroneId][j].getGroupNum() > this.groupList[currentDroneId][checkedIdx[1]].getGroupNum()){
                this.groupList[currentDroneId][j].setGroupNum(this.groupList[currentDroneId][j].getGroupNum()-1);
            }
        }
        
        for(let j = checkedIdx.length-1;j>0;j--){
            this.groupList[currentDroneId].splice(this.groupList[currentDroneId][checkedIdx[j]].getGroupNum()-1,1); 
        }
    }

    merge(currentDroneId){        
        let checkedIdx=[];
        for(let i=0;i<this.groupList[currentDroneId].length;i++) {
          if(this.groupList[currentDroneId][i].getChecked()) 
          {
            checkedIdx.push(i);
            if(this.groupList[currentDroneId][i].getShape() === "Line") return false;
            else if(this.groupList[currentDroneId][i].getShape() === "Underside") return false;
            else if(this.groupList[currentDroneId][i].getShape() === "Merged Circle") return false;
            else if(this.groupList[currentDroneId][i].getShape() === "Merged Rectangle") return false;
          }
        }
        if(checkedIdx.length < 2) return false;
    
        for( let i=0;i<checkedIdx.length-1;i++){
            if(this.groupList[currentDroneId][checkedIdx[i]].getShape() !== 
            this.groupList[currentDroneId][checkedIdx[i+1]].getShape()) return false;  
        }   
        
        if(this.groupList[currentDroneId][checkedIdx[0]].getShape() === "Circle") {      
            this.mergeCircle(currentDroneId, checkedIdx);
        }
        else if(this.groupList[currentDroneId][checkedIdx[0]].getShape() === "Rectangle") {
            // if(checkedIdx.length !== 2) return false;
            this.mergeRectangle(currentDroneId, checkedIdx);
        }  
        return true;
    }
}