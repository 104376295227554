<template>
  <div class="report_detail_crack_info">
    <div class="report_detail_crack_info_title">
      <div class="report_detail_crack_info_no">{{ $t("term.order") }}</div>
      <div class="report_detail_crack_info_type">{{ $t("term.type") }}</div>
      <div class="report_detail_crack_info_thickness">
        {{ $t("term.thickness") }}
      </div>
      <div class="report_detail_crack_info_length">{{ $t("term.length") }}</div>
      <div class="report_detail_crack_info_distance">
        {{ $t("term.distance") }}
      </div>
    </div>
    <div class="report_detail_crack_info_content_wrap">
      <div
        class="report_detail_crack_info_content"
        v-for="(info, i) in infos"
        :key="i"
      >
        <div class="report_detail_crack_info_no">{{ i + 1 }}</div>
        <div class="report_detail_crack_info_type">
          {{ translateInfoType(info.type) }}
        </div>
        <div class="report_detail_crack_info_thickness" v-if="selectedPhoto">
          {{ getCrackWidth(info.crack_width, info.distance, info.type) }} mm
        </div>
        <div class="report_detail_crack_info_length" v-if ="selectedPhoto">
          {{ getCrackLength(info.crack_length, info.distance) }} m
        </div>
        <div class="report_detail_crack_info_distance">
          {{ parseFloat(info.distance).toFixed(3) }} m
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    infos: Array,
    selectedPhoto: Object,
  },
  methods: {
    translateInfoType(type) {
      switch (type) {
        case "crack":
          return this.$t("term.crack");
        case "efflorescence":
          return this.$t("term.efflorescence");
        case "spalling":
          return this.$t("term.spalling");
        case "rebar":
          return this.$t("term.rebar");
        case "leakage":
          return this.$t("term.leakage");
        case "net_crack":
          return this.$t("term.crazing");
        case "etc":
          return this.$t("term.etc");
        default:
          return null;
      }
    },
    getCrackWidth(crackWidth, distance, type) {
      let fovInRadians = this.selectedPhoto.camera.horizontalFov * (Math.PI / 180);
      let realLength = 2 * (distance * Math.tan(fovInRadians / 2));
      let metersPerPixel = realLength / this.selectedPhoto.camera.photoWidth;
      let calcCrackWidth = crackWidth * metersPerPixel * 1000;
      if (type == "crack") calcCrackWidth = calcCrackWidth * 0.1;
      return parseFloat(calcCrackWidth).toFixed(3);
    },
    getCrackLength(crackLength, distance) {
      let fovInRadians = this.selectedPhoto.camera.verticalFov * (Math.PI / 180);
      let realLength = 2 * (distance * Math.tan(fovInRadians / 2));
      let metersPerPixel = realLength / this.selectedPhoto.camera.photoHeight;
      let calcCrackLength = crackLength * metersPerPixel;
      return parseFloat(calcCrackLength).toFixed(3);
    },
  },
};
</script>

<style lang="scss">
.report_detail_crack_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc((100% - 200px) / 2);
  padding: 10px;
}
.report_detail_crack_info_title {
  @include flexbox(center, space-between, row);
  width: calc(100% - 3px);
  height: 30px;
  background: rgba(32, 32, 32, 0.85);
  border-radius: 5px;
  font-size: 1rem;
  color: gray;
}
.report_detail_crack_info_no {
  @include flexbox;
  width: 10%;
  height: 100%;
}
.report_detail_crack_info_type {
  @include flexbox;
  width: 20%;
  height: 100%;
}
.report_detail_crack_info_thickness {
  @include flexbox;
  width: 20%;
  height: 100%;
}
.report_detail_crack_info_length {
  @include flexbox;
  width: 20%;
  height: 100%;
}
.report_detail_crack_info_distance {
  @include flexbox;
  width: 20%;
  height: 100%;
}
.report_detail_crack_info_content_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 30px);
  font-size: 1rem;
  color: $main_white;
  overflow: auto;
}
.report_detail_crack_info_content_wrap::-webkit-scrollbar {
  width: 3px;
  border-radius: 5px;
}
.report_detail_crack_info_content_wrap::-webkit-scrollbar-thumb {
  background: rgba(1, 250, 254, 0.8);
  border-radius: 5px;
}
/* 스크롤바 손잡이:hover 스타일 */
.report_detail_crack_info_content_wrap::-webkit-scrollbar-thumb:hover {
  background: rgba(1, 250, 254, 1);
}
.report_detail_crack_info_content {
  width: 100%;
  height: 40px;
  @include flexbox(center, space-between, row);
  padding: 20px 0px 0px 0px;
  flex-shrink: 0;
}
</style>
