export default class Photo{
  constructor(photo){
    this.id = null;
    this.albumId = null;
    this.albumPhotoPos = null;
    this.albumPhotoPath = null;
    this.albumPhotoThumbnailsPath = null;
    this.albumPhotoResizedPath = null;
    this.camera = null;
    this.albumCrackInformation = null;
    this.albumOtherInformation = null;
    if(photo){
      this.id = photo.id;
      this.albumId = photo.albumId;
      this.albumPhotoPos = photo.albumPhotoPos;
      this.albumPhotoPath = photo.albumPhotoPath;
      this.albumPhotoThumbnailsPath = photo.albumPhotoThumbnailsPath;
      this.albumPhotoResizedPath = photo.albumPhotoResizedPath;
      this.camera = photo.camera;
      this.albumCrackInformation = photo.albumCrackInformation;
      this.albumOtherInformation = photo.albumOtherInformation;
    }
  }
}