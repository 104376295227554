<template>
  <div id="inspection_handy_button_controller">
    <div class="inspection_handy_button_box shooting_mode_box">
      <!-- <p class="button_title">{{ $t("term.selectShootingMode") }}</p> -->

      <button
        class="handy_default_control_button"
        v-if="joyStickData.shootingMode === ''"
        @click="changeShootingMode('start')"
      >
        {{ $t("term.singleShot") }}
      </button>
      <button
        class="handy_default_color_control_button"
        v-else-if="joyStickData.shootingMode === 'singleShot'"
        @click="changeShootingMode('intervalShot')"
      >
        {{ $t("term.singleShot") }}
      </button>
      <button
        class="handy_default_color_control_button"
        v-else
        @click="changeShootingMode('singleShot')"
      >
        {{ $t("term.intervalShot") }}
      </button>
    </div>
    <div class="inspection_handy_button_box iso_box">
      <!-- <p class="button_title">ISO</p> -->
      <button
        class="handy_default_control_button"
        v-if="joyStickData.dayNightTime === ''"
        @click="dayNightTimeSetting('start')"
      >
        {{ $t("term.dayTime") }}
      </button>
      <button
        class="handy_default_color_control_button"
        v-else-if="joyStickData.dayNightTime === 'dayTime'"
        @click="dayNightTimeSetting('nightTime')"
      >
        {{ $t("term.dayTime") }}
      </button>
      <button
        class="handy_default_color_control_button"
        v-else
        @click="dayNightTimeSetting('dayTime')"
      >
        {{ $t("term.nightTime") }}
      </button>
    </div>
    <div class="inspection_handy_button_box shooting_button_box">
      <!-- <p class="button_title">{{ $t("term.shootingControl") }}</p> -->
      <template
        v-if="
          joyStickData.shootingMode === 'singleShot' ||
          joyStickData.shootingMode === ''
        "
      >
        <div class="singleShot_button_box">
          <button
            class="single_shot_button"
            @click="handyShootingStart"
          ></button>
        </div>
      </template>
      <template v-else>
        <div
          class="interval_shot_button_box"
          :class="
            !joyStickData.intervalShootingOn
              ? 'shooting_start'
              : 'shooting_stop'
          "
        >
          <button
            v-if="!joyStickData.intervalShootingOn"
            class="interval_shot_button"
            @click="handyShootingStart"
          >
            <!-- {{ $t("term.shooting") }} -->
          </button>
          <button
            v-else
            class="interval_shot_button"
            @click="handyShootingStop"
          >
            <!-- {{ $t("term.stopShooting") }} -->
          </button>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import "./InspectionPlanMenu.scss";

export default {
  name: "ShootingControlModal",
  components: {},
  emits: [
    "handleChangeHandyShootingMode",
    "handleShootingStatus",
    "handleSendHandyDayNightTime",
    "handleChangeIntervalShootingState",
    "connectVideoStream",
  ],
  props: {
    joyStickData: {
      type: Object,
      required: true,
    },
    droneControl: {
      type: Array,
      required: false,
    },
    currentDroneId: {
      type: Number,
      required: false,
    },
    pitchMin: {
      type: Number,
      required: true,
    },
    pitchMax: {
      type: Number,
      required: true,
    },
    yawMin: {
      type: Number,
      required: true,
    },
    yawMax: {
      type: Number,
      required: true,
    },
  },
  methods: {
    /**
     * 핸디 촬영 모드 설정
     */
    changeShootingMode(mode) {
      if (this.droneControl[this.currentDroneId]) {
        this.$emit("handleChangeHandyShootingMode", mode);
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    handyShootingStart() {
      if (this.droneControl[this.currentDroneId]) {
        if (this.joyStickData.shootingMode === "") {
          // handy 촬영 모드가 빈 string값일 때
          return this.$store.commit(
            "openAlert",
            this.$t("droneAlert.pleaseSelectShootingModeOfHandy")
          );
        }

        if (this.joyStickData.shootingMode === "intervalShot") {
          this.$emit("handleChangeIntervalShootingState", true);
        }
        this.$emit("handleShootingStatus", "shootingStart");
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    /**
     * 핸디 촬영 멈춤
     */
    handyShootingStop() {
      if (this.droneControl[this.currentDroneId]) {
        if (this.joyStickData.shootingMode === "intervalShot") {
          if (
            confirm(`${this.$t("droneAlert.ReallyFinishIntervalShooting")}`)
          ) {
            this.$emit("handleChangeIntervalShootingState", false);
            this.$emit("handleShootingStatus", "shootingStop");

            let vid = document.getElementById("camera");

            vid.addEventListener("play", function () {
              console.log("The video has started to play");
            });

            vid.addEventListener("pause", function () {
              console.log("The video has paused");
              /**
               * TODO : 이 부분은 진짜 pause가 찍히는지 테스트해 봐야함
               */
              this.$emit("connectVideoStream"); // 비디오 재연결
            });

            /**
             * TODO : 근데 일단 pause 상태가 아니더라도 비디오 재연결해준다.
             */
            this.$emit("connectVideoStream"); // 비디오 재연결
          }
          return;
        }

        this.$emit("handleShootingStatus", "shootingStop");
        return;
      }
      return this.$store.commit(
        "openAlert",
        this.$t("droneAlert.pleaseSetDroneTypeAndId")
      );
    },
    /**
     * 핸디 ISO 주/야간 설정하기
     */
    dayNightTimeSetting(time) {
      this.$emit("handleSendHandyDayNightTime", time);
    },
  },
};
</script>

<style lang="scss">
#inspection_handy_button_controller {
  @include flexbox(flex-start, flex-start, column);
  gap: 20px;

  .inspection_handy_button_box {
    @include flexbox(center, flex-start, row);
    gap: 8px;

    .button_title {
      color: $main_white;
      font-size: 1.2rem;
      min-width: 83px;
    }

    .default_border_button,
    .default_border_color_button {
      min-width: 100px;
      padding: 10px;
    }

    .singleShot_button_box {
      width: 80px;
      height: 80px;
      border-radius: 50px;
      background: $main_white;
      @include flexbox;

      .single_shot_button {
        width: 90%;
        height: 90%;
        border-radius: 50px;
        border: 2px solid #000;
        background: $main_white;
        transition: 0.2s;

        &:active {
          border: 8px solid #000;
        }
      }
    }

    .interval_shot_button_box {
      width: 80px;
      height: 80px;
      border-radius: 50px;
      background: $main_white;
      @include flexbox;

      &.shooting_start {
        transition: 0.15s;
        .interval_shot_button {
          width: 90%;
          height: 90%;
          border-radius: 50px;
          border: 2px solid #000;
          background: #ff2f34;

          &:active {
            border: 8px solid #000;
          }
        }
      }

      &.shooting_stop {
        border: 3px solid $main_white;
        background: #000;
        transition: 0.15s;

        .interval_shot_button {
          width: 50%;
          height: 50%;
          border-radius: 4px;
          background: #ff2f34;

          &:active {
            width: 40%;
            height: 40%;
          }
        }
      }
    }
  }
}
</style>
